import Service from '@/shared/classes/service'
import Company from '@/shared/modules/company/company.model'

export default class ToolLocationService extends Service {
  static prefix: string = 'tool-locations'

  static deactivate(company: Company, toolLocationUuid: string): Promise<any> {
    return this.put(`company/${ company.slug }/${ this.prefix }/${ toolLocationUuid }/deactivate`, {})
  }
}
